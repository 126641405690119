import React, { useState } from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import LambdaFetch from '../functions/FetchFromLambda';
import getTableOptions from '../constants/TableOptions';
import DialogTable from './DialogTable';
import {
  invoiceCols,
  invoiceWithVendorCols,
  invoiceDistCols,
  invoicePaymentCols,
  activityCols,
  // invoiceReactTableCols
} from '../constants/VendorCols';
import PoDetailDialog from './PoDetailDialog';
import { dateFormat } from '../utils/formatter';
import { ServerSideTable } from './ServerSideTable';
import ReactTableServerSide from '../components/ReactTableServerSide';
import { useInvoiceReactTableCols } from '../hooks/VendorCols';

export function VendorInvoicesServerSide(props) {
  const [objInfo, setObjInfo] = useState(null);
  const [payment, setPayment] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [activityInfo, setActivityInfo] = useState(null);

  const invoiceReactTableCols = useInvoiceReactTableCols();

  const handleClose = () => {
    setObjInfo(null);
    setPayment(null);
    setSelectedInvoice(null);
    setActivityInfo(null);
  };
  const [poData, setpoData] = useState({
    poHeaderData: null,
    poDetailData: null,
    poReceive: null,
    poReleases: null,
    open: false,
  });
  const getDistribData = async (objId) => {
    try {
      const resp = await LambdaFetch(
        'vendors',
        'post',
        props.accessToken,
        JSON.stringify({
          action: 'invoice-distrib-read',
          objId,
        }),
        null,
        props.credentials
      );

      if (resp.success) {
        setObjInfo(
          resp.data.distrib.map((item) => {
            return [
              item.DIST_SEQ_NBR,
              item.DISTRIB_DATE,
              item.DESCRIPTION,
              item.DIS_ACCT_UNIT,
              `${item.DIS_ACCOUNT}${
                item.DIS_SUB_ACCT ? `-${item.DIS_SUB_ACCT}` : ''
              }`,
              item.ACTIVITY ? (
                <div
                  className="editLink"
                  onClick={() => {
                    const win = window.open(
                      `/actrans?id=${item.ATN_OBJ_ID}&type=OBJ_ID`,
                      '_blank'
                    );
                    win.focus();
                  }}
                >
                  {item.ACTIVITY}
                </div>
              ) : (
                ''
              ),
              item.ORIG_BASE_AMT,
            ];
          })
        );
      } else {
        setObjInfo(null);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const updateRelease = (poNum, company, code, release) => {
    getPo(poNum, company, code, release)
  }

  const getPo = async (id, company, code, release) => {
    try {
      if (!id || !company) return null
      setpoData({ ...poData, open: true })
      const resp = await LambdaFetch(
        'po',
        'post',
        props.credentials.user.accessToken,
        JSON.stringify({
          action: 'get-po',
          company: company,
          po: id,
          code: code,
          release: release
        }),
        '',
        props.credentials,
        '',
        'No Po Found'
      )
      if(!resp) throw new Error('No Po')
      setpoData({
        ...poData,
        poHeaderData: resp.data.poHeader[0],
        poDetailData: resp.data.poDetail,
        poReceive: resp.data.poReceive,
        poReleases: resp.data.poReleases,
        open: true,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getPaymentData = async (inv, comp) => {
    try {
      const resp = await LambdaFetch(
        'vendors',
        'post',
        props.accessToken,
        JSON.stringify({
          action: 'invoice-payment-read',
          vendor: props.vendor,
          invoice: inv.trim(),
          company: comp,
        }),
        null,
        props.credentials
      );
      const payments = resp.data.payment.map((p) => {
        p['STATUS'] = `${p.REC_STATUS_DESC} (${p.REC_STATUS})`;
        p['PAYMENT_CODE'] = `${p.BANK_INST_DESC} (${p.BANK_INST_CODE})`;
        return p;
      });
      if (resp.success) {
        setPayment(payments);
      } else {
        setObjInfo(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!props.vendor || !props.selectedGroup) return null;

  const options = getTableOptions({
    cols: invoiceCols,
    fileName: props.fileName,
  });

  const tableDataTransformation = (rows) => {
    return rows.map((row) => {
      return {
        ...row,
        PO_LINK: row.PO_NUMBER ? (
          <div
            className="editLink"
            onClick={() => {
              getPo(row.PO_NUMBER, row.COMPANY, row.PO_CODE);
            }}
          >
            {`${row.PO_CODE && `${row.PO_CODE} - `}${row.PO_NUMBER.trim()}${
              row.PO_RELEASE && ` - ${row.PO_RELEASE}`
            }`}
          </div>
        ) : (
          ''
        ),
        Distribution: (
          <div
            className="editLink"
            onClick={() => {
              getDistribData(row.OBJ_ID);
              setSelectedInvoice(row.INVOICE);
            }}
          >
            view
          </div>
        ),
        Payment: (
          <div
            className="editLink"
            onClick={() => {
              getPaymentData(row.INVOICE, row.COMPANY);
              setSelectedInvoice(row.INVOICE);
            }}
          >
            view
          </div>
        ),
      };
    });
  };
  const invCols = props.showVendor ? invoiceWithVendorCols : invoiceCols;

  return (
    <>
      <PoDetailDialog
        {...props}
        poHeaderData={poData.poHeaderData}
        poDetailData={poData.poDetailData}
        poReceive={poData.poReceive}
        poReleases={poData.poReleases}
        updateRelease={updateRelease}
        open={poData.open}
        close={() =>
          setpoData({
            ...poData,
            poHeaderData: null,
            poDetailData: null,
            poReceive: null,
            poReleases: null,
            open: false,
          })
        }
      />

      <DialogTable
        tableTitle={selectedInvoice}
        isOpen={!!payment}
        handleClose={handleClose}
        tableData={payment}
        cols={invoicePaymentCols}
        fileName={`${props.fileName}_Payments_${selectedInvoice}`}
        noFooter
      />

      <DialogTable
        tableTitle={selectedInvoice}
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={invoiceDistCols}
        totalColIndex={6}
        fileName={`${props.fileName}_Distribution_${selectedInvoice}`}
      />
      <DialogTable
        isOpen={!!activityInfo}
        handleClose={() => setActivityInfo(null)}
        tableData={activityInfo}
        cols={activityCols}
        simpleTable={true}
      />

      <div style={{ marginTop: '1rem ' }}>
        {/* <ServerSideTable
          {...props}
          params={{ vendor: props.vendor, vendorGroup: props.selectedGroup }}
          action='vendor-invoice-serverside'
          apiResource={'vendors'}
          transform={tableDataTransformation}
          tableTitle={''}
          rowsPerPage={100}
          tableColumns={invCols}
          noPagination
          noSort
          downloadAction={'vendor-invoice-serverside-download'}
        /> */}
        <ReactTableServerSide
          {...props}
          params={{ vendor: props.vendor, vendorGroup: props.selectedGroup }}
          action="vendor-invoice-serverside-v2"
          apiResource={'vendors'}
          transform={tableDataTransformation}
          tableTitle={''}
          tableColumns={invoiceReactTableCols}
          noPagination
          noSort
          downloadAction={'vendor-invoice-serverside-download-v2'}
          // from array of INVOICE_DTE value to separate INVOICE_DATE_FROM and INVOICE_DATE_TO
          separatedDateFilters={{
            INVOICE_DTE: ['INVOICE_DATE_FROM', 'INVOICE_DATE_TO'],
          }}
        />
      </div>
    </>
  );
}
