import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import LambdaFetch from '../functions/FetchFromLambda';
import TableMui from '../components/TableMui';
import LoadingButton from '../components/LoadingButton';
import getTableOptions from '../constants/TableOptions';
import { dateTimeFormat } from '../utils/formatter';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ReactTable from '../components/ReactTable';

export default function Users(props) {
  const { credentials } = props.fetchInitialData;

  const [state, setstate] = useState({
    users: [],
    isLoading: false,
    avaiableRoles: 'all-access',
    isFetching: true,
  });
  const [currentValue, setCurrentValue] = useState(null);
  const [currentRoles, setCurrentRoles] = useState({});
  useEffect(() => {
    async function fetchMyAPI() {
      fetchData();
    }
    fetchMyAPI();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'users-read',
        }),
        '',
        credentials
      );

      const avaiableRoles = resp.data.roles
        ? resp.data.roles.R_VALUE
        : 'all-access';

      setstate({
        ...state,
        avaiableRoles,
        users: resp.data.users,
        isLoading: false,
        isFetching: false,
      });
      setCurrentRoles({});
      setCurrentValue(null);
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedRoles = getTrueProperties(currentRoles, true);
    setstate({ ...state, isLoading: true });

    await LambdaFetch(
      'admin',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'users-update',
        roles: updatedRoles,
        id: currentValue.ID,
      }),
      '',
      credentials
    );

    await fetchData();
  };

  const handleChange = (e) => {
    setCurrentRoles({
      ...currentRoles,
      [e.target.name]: e.target.checked,
    });
  };

  const tableData = state.users.map((row) => {
    return {
      ...row,
      edit: (
        <div
          className="editLink"
          onClick={() => {
            setCurrentValue(row);
            setCurrentRoles(
              row.roles.split(',').reduce((acc, cur) => {
                return { ...acc, [cur]: true };
              }, {})
            );
          }}
        >
          edit
        </div>
      ),
    };
  });
  const options = getTableOptions({
    cols: userCols,
    fileName: `users`,
  });

  options['selectableRows'] = 'multiple';
  return (
    <div style={{ margin: '1rem' }}>
      {!!currentValue && (
        <Dialog open={!!currentValue} fullWidth maxWidth="xs">
          <DialogTitle id="alert-dialog-title">
            {currentValue.username}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <FormGroup>
                    {state.avaiableRoles.split(',').map((role) => {
                      return (
                        <FormControlLabel
                          key={role}
                          control={
                            <Checkbox
                              name={role}
                              onChange={handleChange}
                              defaultChecked={
                                currentValue.roles
                                  ? currentValue.roles.split(',').includes(role)
                                  : false
                              }
                            />
                          }
                          label={role}
                        />
                      );
                    })}
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentValue(null)}>Cancel</Button>

              <LoadingButton
                label="Edit"
                isLoading={state.isLoading}
                color="primaryVLButton"
                buttonType="submit"
              />
            </DialogActions>
          </form>
        </Dialog>
      )}

      <ReactTable
        isLoading={state.isFetching}
        cols={clientReactUserCols}
        data={tableData}
      />
    </div>
  );
}

const clientReactUserCols = [
  {
    accessorKey: 'username',
    header: 'Username',
    filterFn: 'contains',
  },
  {
    accessorKey: 'roles',
    header: 'Roles',
    filterFn: 'contains',
  },
  {
    accessorKey: 'created',
    header: 'Creation Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateTimeFormat(cell.getValue()),
  },
  {
    accessorKey: 'edit',
    header: 'Edit',
    filterFn: 'contains',
  },
];
const userCols = [
  {
    name: 'username',
    label: 'Username',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'roles',
    label: 'Roles',
    options: {
      filter: true,
    },
  },
  {
    name: 'created',
    label: 'Creation Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: dateTimeFormat,
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
      download: false,
    },
  },
];

function getTrueProperties(obj, boolValue) {
  // Get all property names of the object
  const propertyNames = Object.keys(obj);

  // Filter property names based on the boolean value
  const trueProperties = propertyNames.filter(
    (propertyName) => obj[propertyName] === boolValue
  );

  // Return comma-separated string of true property names
  return trueProperties.join(',');
}
