import React from 'react'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import {
  locationCols,locationReactCols
} from '../constants/VendorCols'
import ReactTable from './ReactTable'

export default function VendorLocations (props) {
  if (!props.data) return null
	const options = getTableOptions({cols: locationCols, fileName: props.fileName});

  return (
    <>

<ReactTable cols={locationReactCols} data={props.data} />

      {/* <TableMui
        cols={locationCols}
        data={props.data}
        options={options}
        title=''
      /> */}
    </>
  )
}
