import React from 'react'
import NumberFormat from 'react-number-format'
import { dateFormat, currencyFormat } from '../utils/formatter'

const numberRender = (value, tableMeta, updateValue) => {
  return (
    <NumberFormat
      value={parseFloat(value).toFixed(2)}
      thousandSeparator={true}
      prefix={'$'}
      displayType='text'
    />
  )
}

export const invoiceCols = [
  {
    name: 'COMPANY',
    label: 'Co',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE',
    label: 'Invoice',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'VOUCHER_NBR',
    label: 'Voucher Number',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE_DTE',
    label: 'Invoice Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BASE_INV_AMT',
    label: 'Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_INV_AMT',
    label: 'Trans Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DUE_DATE',
    label: 'Due Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'PO_NUMBER',
    label: 'Po',
    options: {
      download: true,
      filter: true,
      sort: true,
      display: false
    }
  },
  {
    name: 'PO_LINK',
    label: 'Po',
    options: {
      download: false,
      filter: true,
      sort: true
    }
  },
  {
    name: 'Distribution',
    label: 'Distribution',
    options: {
      download: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'Payment',
    label: 'Payment',
    options: {
      download: false,
      filter: false,
      sort: false
    }
  },
  {
    name: 'OPERATOR',
    label: 'Operator',
    options: {
      filter: false,
      sort: false
    }
  }
]

export const invoiceReactTableCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'INVOICE',
    header: 'Invoice',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'INVOICE_TYPE',
    header: 'Type',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'VOUCHER_NBR',
    header: 'Voucher Number',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'INVOICE_DTE',
    header: 'Invoice Date',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'BASE_INV_AMT',
    header: 'Amount',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'TRAN_INV_AMT',
    header: 'Trans Amount',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'DUE_DATE',
    header: 'Due Date',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  // {
  //   accessorKey: 'PO_NUMBER',
  //   header: 'Po',
  //   enableSorting: false,
  //   enableColumnFilter: true,
  //   size: 1
  // },
  {
    accessorKey: 'PO_LINK',
    header: 'Po',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'Distribution',
    header: 'Distribution',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'Payment',
    header: 'Payment',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'OPERATOR',
    header: 'Operator',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  }
]

export const invoiceDistCols = [
  {
    name: 'DIST_SEQ_NBR',
    label: 'Seq',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DISTRIB_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DIS_ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ORIG_BASE_AMT',
    label: 'Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true
    }
  }
]

export const invoicePaymentCols = [
  {
    name: 'SEQ_NBR',
    label: 'Num',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REMIT_TO_CODE',
    label: 'Remit Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CASH_CODE',
    label: 'Cash Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_PMT_AMT',
    label: 'Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true
    }
  },
  {
    name: 'CHECK_DATE',
    label: 'Check Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PAYMENT_CODE',
    label: 'Payment Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_NBR',
    label: 'Trans Number',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INCOME_CODE',
    label: 'Income Code',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const locationCols = [
  {
    name: 'LOCATION_CODE',
    label: 'Location Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LOC_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVE_STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'EFFECTIVE_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ADDR1',
    label: 'Address 1',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ADDR2',
    label: 'Address 2',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ADDR3',
    label: 'Address 3',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ADDR4',
    label: 'Address 4',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CITY_ADDR5',
    label: 'City',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATE_PROV',
    label: 'State',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'POSTAL_CODE',
    label: 'Zip',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COUNTY',
    label: 'County',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COUNTRY',
    label: 'Country',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CUR_ADDR',
    label: 'Current',
    options: {
      filter: true,
      sort: true
    }
  }
]
// {
//   name: '',
//   label: '',
//   options: {
//     filter: true,
//     sort: true,
//   },
// },

export const poCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PO_NUMBER',
    label: 'PO Number',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PO_CODE',
    label: 'PO Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PO_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BUYER_CODE',
    label: 'Buyer',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TOT_PRD_AMT',
    label: 'Amount',
    options: {
      customBodyRender: numberRender,
      filter: true,
      sort: true
    }
  },
  {
    name: 'NBR_LINES',
    label: 'Line Count',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'view',
    label: 'Payment',
    options: {
      download: false,
      filter: false,
      sort: false
    }
  }
]

export const poReactTableCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'PO_NUMBER',
    header: 'PO Number',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'PO_CODE',
    header: 'PO Code',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'PO_DATE',
    header: 'Date',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'BUYER_CODE',
    header: 'Buyer',
    enableSorting: false,
    enableColumnFilter: true,
    size: 1
  },
  {
    accessorKey: 'TOT_PRD_AMT',
    header: 'Amount',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'NBR_LINES',
    header: 'Line Count',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  },
  {
    accessorKey: 'view',
    header: 'Payment',
    enableSorting: false,
    enableColumnFilter: false,
    size: 1
  }
]

export const activityCols = [
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY_DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_CATEGORY_DESCRIPTION',
    label: 'Catergory Description',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const invoiceWithVendorCols = [
  {
    name: 'VENDOR',
    label: 'Ven',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'VENDOR_VNAME',
    label: 'Name',
    options: {
      filter: true,
      sort: true
    }
  },
  ...invoiceCols
]

export const poWithVendorCols = [
  {
    accessorKey: 'VENDOR',
    header: 'Ven',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'VENDOR_VNAME',
    header: 'Name',
    filterFn: 'contains',
    size: 1
  },
  ...poReactTableCols
]

export const locationReactCols = [
  {
    accessorKey: 'LOCATION_CODE',
    header: 'Location Code',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'LOC_TYPE',
    header: 'Type',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'ACTIVE_STATUS',
    header: 'Status',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'EFFECTIVE_DATE',
    header: 'Date',
    filterFn: 'contains',
    size: 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'ADDR1',
    header: 'Address 1',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'ADDR2',
    header: 'Address 2',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'ADDR3',
    header: 'Address 3',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'ADDR4',
    header: 'Address 4',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'CITY_ADDR5',
    header: 'City',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'STATE_PROV',
    header: 'State',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'POSTAL_CODE',
    header: 'Zip',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'COUNTY',
    header: 'County',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'COUNTRY',
    header: 'Country',
    filterFn: 'contains',
    size: 1
  },
  {
    accessorKey: 'CUR_ADDR',
    header: 'Current',
    filterFn: 'contains',
    size: 1
  }
]
