import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import Popper from '@mui/material/Popper'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList } from 'react-window'
import Typography from '@mui/material/Typography'
import LambdaFetch from '../functions/FetchFromLambda'

const LISTBOX_PADDING = 0 // px

function renderRow (props) {
  const { data, index, style } = props

  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].display}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache (data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent (
  props,
  ref
) {
  const { children, ...other } = props
  const itemData = []
  children.forEach(item => {
    itemData.push(item)
    itemData.push(...(item.children || []))
  })

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })

  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (child.hasOwnProperty('group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export default function Virtualize (props) {
  const [inputVal, setInputVal] = React.useState('')
  const [list, setList] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [val, setVal] = React.useState(null)
  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    if(!props.options){
    fetchMyAPI()
    }
  }, [])


  const onTagsChange = (event, values) => {
    const inputVal = values ? values.display : ''
    setInputVal(inputVal)
    setVal(values)
    props.onChange(values)
  }

  useEffect(() => {
    setInputVal('')
  }, [props.clear])


  const fetchData = async id => {
    try {
      const resp = await LambdaFetch(
        'autocomplete',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'get-values-reports',
          category: props.autoCategory,
          dropdown: props.dropdown
        }),
        '',
        props.fetchInitialData.credentials
      )
      setList(resp.data.results)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }


  return (
    <Autocomplete
      {...props}
      loading={isLoading}
      dontAutoHighlight
      autoSelect
      openOnFocus
      inputValue={inputVal}
      onInputChange={(event, value) => setInputVal(value)}
      onChange={onTagsChange}
      fullWidth
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={props.options ? props.options : list}
      renderInput={params => (
        <TextField
          {...params}
          style={{ margin: 0 }}
          required={props.required}
          label={props.label}
          value={val}
          margin='dense'
          variant={props.variant ? props.variant : 'outlined'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position='start'>
                  <div></div>
                </InputAdornment>
              </>
            )
          }}
        />
      )}
      getOptionLabel={option => option.display}
      renderOption={(props, option) => [props, option]}
      renderGroup={params => params}
    />
  )
}
