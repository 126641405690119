import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  Grid,
  Button,
  Paper,
  InputAdornment,
  TableFooter,
  TableRow,
  TableCell,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LambdaFetch from '../functions/FetchFromLambda';
import LoadingCircle from './common/LoadingCircle';
import { smallScreenWidth } from '../constants/AppConstants';
import ReactTable from './ReactTable'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem',
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999,
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0',
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: (props) => props.primaryColor,
    height: '60px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function TableViewModal(props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    fetched: false,
    isOpen: false
  });
  const classes = useStyles()

  const fetchData = async () => {
    setstate({...state,isOpen: true, isLoading: true})

  };

  const {isOpen} = state
  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        maxWidth="xl"
        fullWidth
        scroll="body"
        keepMounted
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                setstate({
                  ...state,
                  data: null,
                  isOpen:false
                })
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {state.isOpen && (
          <DialogContent style={{ padding: 0 }}>
            {!!state.isLoading ? (

props.children


            // <ReactTable cols={props.cols} data={state.data} />
            ) : (
              <LoadingCircle />
            )}
          </DialogContent>
        )}
      </Dialog>
      <div className="editLink" onClick={() => fetchData()}>view</div>
</div>
  );
}
