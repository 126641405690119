import {dateTimeFormat, dateTimeSecondFormat} from '../utils/formatter';

export const printManagerCols = [
  {
    name: 'id',
    label: 'id',
    options: {
      display: false,
      download: false,
    },
  },
  {
    name: 'document_link',
    label: 'document_link',
    options: {
      display: false,
      download: false,
    },
  },
  {
    name: 'username',
    label: 'Username',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'event_name',
    label: 'Job Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'document_name',
    label: 'Report',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'created_on',
    label: 'Create Date',
    options: {
        customBodyRender: dateTimeSecondFormat,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'completed_on',
    label: 'Completed',
    options: {
      customBodyRender: dateTimeSecondFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'params_link',
    label: 'Params',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'link',
    label: 'Link',
    options: {
      download: false,
      filter: false,
      sort: false,
    },
  },
];


export const athenaPrintManagerCols = [
  {
    name: 'username',
    label: 'Username',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'event_name',
    label: 'Job Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'report_name',
    label: 'Report',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'created_on',
    label: 'Create Date',
    options: {
        customBodyRender: dateTimeSecondFormat,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'params_link',
    label: 'Params',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'fail_reason',
    label: 'Fail Reason',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'link',
    label: 'Link',
    options: {
      download: false,
      filter: false,
      sort: false,
    },
  },
];
